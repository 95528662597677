import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  ScrollView,
  useWindowDimensions,
  ActivityIndicator,
  TouchableOpacity,
  Image,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import { Text, Button, Tag, Card, Input, Table } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import JsxParser from "react-jsx-parser";
import RemoteImage from "../../../../../components/RemoteImage";
import GoalBot from "./GoalBot";

const loadedStyles = JSON.stringify({
  animationContainer: {
    alignSelf: "center",
    width: 300,
    height: 450,
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 10,

    elevation: 9,
  },
});

const component = `<View style={styles.animationContainer}></View>`;

const profileArr = [
  {
    key: "first_name",
    value: "Kevin",
    icon: "finger-print",
  },
  {
    key: "last_name",
    value: "Doyle",
    icon: "finger-print",
  },
  {
    key: "phone",
    value: "+16505555555",
    icon: "call",
  },
  {
    key: "email",
    value: "kevin.doyle@gmail.com",
    icon: "mail",
  },
  {
    key: "dob",
    value: "1997-03-18",
    icon: "earth",
  },
];

const Goal = () => {
  const scrollRef = useRef(null);
  const [animating, setAnimating] = useState(false);

  const successOne = () => (
    <View
      style={[
        styles.databaseView,
        { borderTopColor: "#002626", borderTopWidth: 3, height: "100%" },
      ]}
    >
      <View style={{ width: 250, justifyContent: "space-between" }}>
        <View
          style={{
            alignSelf: "center",
            alignItems: "center",
            marginBottom: 25,
          }}
        >
          <View
            style={{
              height: 50,
              width: 50,
              borderRadius: 50,
              backgroundColor: "#002626",
              marginBottom: 15,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Ionicons name="person" size={24} color="#00ECAC" />
          </View>
          <Text
            style={{
              marginBottom: -10,
              textAlign: "center",
              fontFamily: "Roobert",
            }}
            h6
          >
            Kevin Doyle
          </Text>
          <Text style={{ color: "grey", textAlign: "center" }} font="12px">
            Entity ID: ent_msb8G2Qm7sZDd
          </Text>
        </View>

        <Card style={{ width: "100%", alignSelf: "center", marginBottom: 25 }}>
          <View
            style={{
              width: "100%",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              {profileArr.map((elem, index) => (
                <View
                  key={elem.key}
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: index == [1, 2, 3, 4].length ? 0 : 10,
                  }}
                >
                  <Ionicons
                    name={elem.icon}
                    size={12}
                    color="#002626"
                    style={{ marginRight: 6 }}
                  />
                  <Text style={{ color: "#002626" }} small font="12px">
                    {elem.key}
                  </Text>
                </View>
              ))}
            </View>
            <View>
              {profileArr.map((elem, index) => (
                <View
                  key={elem.value}
                  style={{
                    marginBottom: index == [1, 2, 3, 4].length ? 0 : 10,
                  }}
                >
                  <Text style={{ textAlign: "end" }} small font="12px">
                    {elem.value}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </Card>

        <TouchableOpacity
          style={{
            backgroundColor: "#002626",
            width: "100%",
            paddingVertical: 8,
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: 5,
            flexDirection: "row",
            paddingHorizontal: 15,
          }}
        >
          <Ionicons name="ios-checkmark-circle" size={24} color="#00ECAC" />
          <Text style={{ color: "#00ECAC" }} small b>
            Individual Entity Created
          </Text>
          <View />
        </TouchableOpacity>
      </View>
    </View>
  );
  const successTwo = () => (
    <View
      style={[
        styles.databaseView,
        {
          borderTopColor: "#002626",
          borderTopWidth: 3,
          height: "100%",
          justifyContent: "space-between",
        },
      ]}
    >
      <View style={{ width: 200 }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            marginBottom: -10,
          }}
        >
          <View
            style={{
              height: 20,
              width: 20,
              borderRadius: 50,
              backgroundColor: "#002626",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 10,
              marginTop: 6,
            }}
          >
            <Ionicons name="person" size={11} color="#00ECAC" />
          </View>
          <Text style={{ fontFamily: "Roobert" }} h4>
            Kevin Doyle
          </Text>
        </View>
        <Text style={{ color: "grey" }} font="11px">
          Entity ID: ent_msb8G2Qm7sZDd
        </Text>
        <View style={{ alignSelf: "flex-start" }}>
          <Tag
            type="success"
            style={{ backgroundColor: "#002626", color: "#00ECAC" }}
            invert
          >
            Verified with Wells Fargo
          </Tag>
        </View>
      </View>

      <Card style={{ width: "100%", alignSelf: "center" }}>
        <View
          style={{
            width: "100%",
            alignItems: "center",
            alignSelf: "center",

            marginBottom: 25,
          }}
        >
          <Image
            source={{
              uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Wells_Fargo_Bank.svg/1024px-Wells_Fargo_Bank.svg.png",
            }}
            style={{
              height: 50,
              width: 50,
              borderRadius: 50,
              backgroundColor: "black",
              marginBottom: 15,
              alignItems: "center",
              justifyContent: "center",
            }}
          />
          <Text style={{ color: "grey" }} small b font="12px">
            Merchant
          </Text>
          <Text
            style={{
              marginBottom: -10,
              textAlign: "center",
              fontFamily: "Roobert",
            }}
            h4
          >
            Wells Fargo
          </Text>
        </View>
      </Card>
      <TouchableOpacity
        style={{
          backgroundColor: "#002626",
          width: "100%",
          paddingVertical: 8,
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 5,
          flexDirection: "row",
          paddingHorizontal: 15,
        }}
      >
        <Ionicons name="ios-checkmark-circle" size={24} color="#00ECAC" />
        <Text style={{ color: "#00ECAC" }} small b>
          Bill Linked
        </Text>
        <View />
      </TouchableOpacity>
    </View>
  );
  const successThree = () => (
    <View
      style={[
        styles.databaseView,
        { borderTopColor: "#002626", borderTopWidth: 3, height: "100%" },
      ]}
    >
      <View
        style={{
          justifyContent: "space-between",
          flexDirection: "row",
          height: "100%",
        }}
      >
        <View
          style={{
            height: "100%",
            justifyContent: "space-between",
            borderWidth: 1,
            borderColor: "#eaeaea",
            borderRadius: 5,
            padding: 15,
          }}
        >
          <View
            style={{
              width: 250,
            }}
          >
            <View
              style={{
                width: "100%",
                alignItems: "center",
              }}
            >
              <Image
                source={{
                  uri: "https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,f_auto,q_auto:eco,dpr_1/om6pejoyjy22x28dqika",
                }}
                style={{
                  height: 50,
                  width: 50,
                  borderRadius: 80,
                  backgroundColor: "black",
                  marginBottom: 15,
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth: 0.5,
                  borderColor: "lightgrey",
                }}
              />
              <Text style={{ color: "grey" }} small b font="12px">
                Payment
              </Text>
              <Text
                style={{
                  marginBottom: 5,
                  textAlign: "center",
                  fontFamily: "Roobert",
                }}
                h4
              >
                Deferit
              </Text>
            </View>
          </View>
          <View style={{ alignSelf: "center", width: 220 }}>
            <Text
              style={{ color: "grey", marginBottom: 10, textAlign: "center" }}
              small
            >
              Payment on behalf of Kevin Doyle to pay his bill at Wells Fargo
            </Text>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: "#002626",
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 5,
              flexDirection: "row",
              paddingHorizontal: 15,
            }}
          >
            <Ionicons name="ios-checkmark-circle" size={24} color="#00ECAC" />
            <Text style={{ color: "#00ECAC" }} small b>
              Deferit Sent $2000
            </Text>
            <View />
          </TouchableOpacity>
        </View>

        <View
          style={{
            alignSelf: "center",
            marginVertical: 10,
            width: 125,
            borderTopWidth: 1,
            borderTopColor: "grey",
            borderStyle: "dashed",
            height: 0,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "#002626",
              alignItems: "center",
              justifyContent: "center",
              paddingVertical: 6,
              paddingHorizontal: 15,
              alignSelf: "center",
              borderRadius: 50,
            }}
          >
            <Text style={{ color: "white" }} small b>
              $2000
            </Text>
          </View>
        </View>

        <View
          style={{
            height: "100%",
            justifyContent: "space-between",
            borderWidth: 1,
            borderColor: "#eaeaea",
            borderRadius: 5,
            padding: 15,
            width: 300,
            alignSelf: "center",
          }}
        >
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: -10,
              }}
            >
              <View
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 50,
                  backgroundColor: "#002626",
                  alignItems: "center",
                  justifyContent: "center",
                  marginRight: 10,
                }}
              >
                <Ionicons name="person" size={11} color="#00ECAC" />
              </View>
              <Text style={{ fontFamily: "Roobert", marginTop: 8 }} h5>
                Kevin Doyle Bill
              </Text>
            </View>
            <Text style={{ color: "grey", alignSelf: "center" }} font="11px">
              Entity ID: ent_msb8G2Qm7sZDd
            </Text>
            <Text
              style={{ color: "grey", marginTop: -10, alignSelf: "center" }}
              font="11px"
            >
              Payment ID: ffdj8734hhsi
            </Text>
          </View>
          <View
            style={{
              width: "100%",
              alignItems: "center",
              alignSelf: "center",

              marginBottom: 25,
            }}
          >
            <Image
              source={{
                uri: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b3/Wells_Fargo_Bank.svg/1024px-Wells_Fargo_Bank.svg.png",
              }}
              style={{
                height: 50,
                width: 50,
                borderRadius: 50,
                backgroundColor: "black",
                marginBottom: 15,
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            <Text style={{ color: "grey" }} small b font="12px">
              Merchant
            </Text>
            <Text
              style={{
                marginBottom: -10,
                textAlign: "center",
                fontFamily: "Roobert",
              }}
              h4
            >
              Wells Fargo
            </Text>
          </View>
          <TouchableOpacity
            style={{
              backgroundColor: "#002626",
              width: "100%",
              paddingVertical: 8,
              alignItems: "center",
              justifyContent: "space-between",
              borderRadius: 5,
              flexDirection: "row",
              paddingHorizontal: 15,
            }}
          >
            <Ionicons name="ios-checkmark-circle" size={24} color="#00ECAC" />
            <Text style={{ color: "#00ECAC" }} small b>
              Merchant Received $2000
            </Text>
            <View />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );

  const componentVariants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ width: "100%" }}>
          <Text
            style={{
              color: "#127575",
              marginBottom: -10,
              fontFamily: "Roobert",
            }}
            h1
          >
            Mission
          </Text>
          <Text
            style={{
              color: "#002626",
              marginBottom: -10,
              fontFamily: "Roobert",
            }}
            h1
          >
            Pay a User's Bill
          </Text>
          <View style={{ marginVertical: 15 }} />
          <Text style={{ color: "#002626" }} font="16px">
            Deferit is looking to modernize and streamline their internal bill
            payment operations with the goal of reducing internal overhead and
            improving end-user experience.
          </Text>
          <View style={{ marginVertical: 10 }} />
          <View style={{ alignSelf: "flex-start" }}>
            <Button
              style={{ fontWeight: "bold" }}
              type="secondary"
              onClick={() =>
                // lastComp.current.scrollIntoView({
                //   behavior: "smooth",
                // })
                {
                  setAnimating(true);
                }
              }
            >
              Start Animation
            </Button>
          </View>
        </View>
      </View>
      <View style={{ marginVertical: 20 }} />
      {/* <JsxParser
        bindings={{
          styles,
        }}
        components={{
          View,
          ScrollView,
          motion,
          ActivityIndicator,
          Text,
          Button,
          Tag,
          TouchableOpacity,
          Card,
          Input,
        }}
        jsx={component}
      /> */}
      <View style={styles.scrollContainer}>
        <ScrollView
          ref={scrollRef}
          horizontal
          contentContainerStyle={styles.animationContainer}
        >
          <motion.div style={{ alignSelf: "center" }}>
            {successOne()}
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            style={{ alignSelf: "center" }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            onAnimationComplete={() => {
              // if (animating)
              //   scrollRef.current.scrollBy({
              //     top: 0,
              //     left: +1000,
              //     behavior: "smooth",
              //   });
            }}
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 0.2 }}
            style={{ alignSelf: "center", height: "100%" }}
          >
            {successTwo()}
          </motion.div>

          <motion.div
            onAnimationComplete={() => {
              if (animating)
                scrollRef.current.scrollBy({
                  top: 0,
                  left: +1000,
                  behavior: "smooth",
                });
            }}
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 0.8 }}
            style={{ alignSelf: "center" }}
          >
            <View style={styles.nextBorder} />
          </motion.div>

          <motion.div
            variants={componentVariants}
            initial="hidden"
            animate={animating ? "visible" : "hidden"}
            transition={{ delay: 0.8 }}
            style={{ alignSelf: "center", height: "100%" }}
          >
            {successThree()}
          </motion.div>
        </ScrollView>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  scrollContainer: {
    borderWidth: 1,
    borderColor: "lightgrey",
    borderRadius: 10,
  },
  animationContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    minWidth: "100%",
    paddingVertical: 40,
    paddingHorizontal: 25,
  },
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
  nextBorder: {
    height: 0,
    width: 75,
    borderTopWidth: 1,
    borderTopColor: "grey",
    borderStyle: "dashed",
    alignSelf: "center",
    marginHorizontal: 20,
  },
  // ...JSON.parse(loadedStyles),
});

export default Goal;
