import React, { useContext, useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  View,
  Image,
  ScrollView,
  TouchableOpacity,
} from "react-native";
import { Context as PlanContext } from "@context/PlanContext";
import { Context as LocalContext } from "@context/LocalContext";
import { Text, Input, Button, Card } from "@geist-ui/react";
import colors from "@res/colors";
import fonts from "@res/fonts";
import { Ionicons } from "@expo/vector-icons";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const MemoImage = React.memo(function ({ source, style }) {
  return <Image source={source} style={style} />;
});

const Local = ({ order, stepContent }) => {
  const { selectTab } = useContext(PlanContext);
  const {
    modDatabase,
    state: { localDatabase },
  } = useContext(LocalContext);

  const onSelect = (merchant) => {
    modDatabase({ key: "selected_merchant", value: merchant });
  };

  return (
    <View style={{ width: "100", minHeight: 500 }}>
      <View
        style={[
          styles.databaseView,
          {
            borderTopColor: "#002626",
            borderTopWidth: 3,
            width: "80%",
            maxWidth: 600,
          },
        ]}
      >
        <View style={{ marginBottom: 25 }}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: -10,
            }}
          >
            <Ionicons
              name="business"
              size={25}
              color="#002626"
              style={{ marginRight: 8 }}
            />
            <Text style={{ fontFamily: "Roobert", color: "#002626" }} h3>
              Select a Merchant
            </Text>
          </View>
          <Text style={{ color: "#002626" }} font="15px">
            We'll save this to link{" "}
            {localDatabase.first_name ? localDatabase.first_name : "your user"}
            's bill
          </Text>
        </View>

        <View
          style={{
            borderColor: "lightgrey",
            borderWidth: 0.5,
            borderRadius: 5,
            marginBottom: 40,
          }}
        >
          <ScrollView
            contentContainerStyle={{
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              maxHeight: 300,
              paddingVertical: 15,
              paddingHorizontal: 15,
              alignItems: "flex-start",
            }}
          >
            {stepContent.content.data.map((elem) => {
              const isSelected =
                localDatabase.selected_merchant != null &&
                `${localDatabase.selected_merchant.mch_id}` == `${elem.mch_id}`;

              return (
                <TouchableOpacity
                  key={`${elem.mch_id}`}
                  onPress={() => onSelect(elem)}
                  style={{
                    marginVertical: 15,
                    marginHorizontal: 15,
                    width: "25%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <motion.div whileHover={{ scale: 1.2 }} layout>
                    <MemoImage
                      source={{ uri: elem.logo }}
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        borderColor: isSelected ? "#002626" : "#F8F8F8",
                        borderWidth: isSelected ? 4 : 4,
                        marginBottom: 8,
                      }}
                    />
                  </motion.div>
                  {isSelected ? (
                    <Text
                      small
                      style={{
                        color: "#002626",
                        width: "100%",
                        textAlign: "center",
                      }}
                      font="12px"
                      b
                    >
                      {elem.parent_name}
                    </Text>
                  ) : (
                    <Text
                      small
                      style={{
                        color: "#002626",
                        width: "100%",
                        textAlign: "center",
                      }}
                      font="12px"
                    >
                      {elem.parent_name}
                    </Text>
                  )}
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
        {!localDatabase.selected_merchant ||
        localDatabase.selected_merchant == null ? (
          <Button style={{ fontWeight: "bold" }} disabled>
            No Merchant Selected
          </Button>
        ) : (
          <Button
            width={"100%"}
            style={{
              padding: 0,
              backgroundColor: "#002626",
              borderColor: "#002626",
            }}
            type="secondary-light"
            onClick={() => {
              selectTab({ index: order + 1, type: "manual" });
            }}
          >
            <Text style={{ color: "#00ECAC" }} b>
              {localDatabase.selected_merchant.parent_name} Selected
            </Text>
          </Button>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  databaseView: {
    padding: 30,
    alignSelf: "center",
    borderRadius: 10,
    backgroundColor: "white",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.1,
    shadowRadius: 15,

    elevation: 9,
  },
});

export default Local;
